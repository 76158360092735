import React from "react";
import SEO from "../components/seo";

function Company({ location }) {
    return (
        <>
            <SEO
                location={location}
                breadcrumb={"Company"}
                meta_title="The company behind Scrum Mate"
                description={"Scrum Mate is a bootstrapped product, developed by Adaptive Consulting Ltd., an agile coaching company."}
            />
            <section className={"pt-32 w-full md:w-3/5 mx-auto px-8 mx-px-0"}>
                <div className={"mb-12"}>
                    <h1 className={"text-4xl mb-4"}>About us</h1>
                    <p>Scrum Mate is designed and developed by Adaptive Consulting Ltd an agile consulting company.</p>
                </div>
                <div className={"mb-12"}>
                    <h1 className={"text-4xl mb-4"}>Support</h1>
                    <p>
                        <a href="mailto:help@scrummate.com" className={"text-blue-500 font-extrabold"}>
                            help@scrummate.com
                        </a>
                    </p>
                </div>
                <div className={"mb-12"}>
                    <h1 className={"text-4xl mb-4"}>Mailing address</h1>
                    <p>1117 Budapest, Hauszmann Alajos u. 3/B</p>
                    <p>Hungary</p>
                    <p>Phone: (+36) 1 319 0056</p>
                </div>
            </section>
        </>
    );
}

export default Company;
